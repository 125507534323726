import axiosConfig from "../utils/axiosConfig";

const signin = (email, password, callback) => {
  axiosConfig("/auth/signin", {
    data: {
      email,
      password,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const token = (callback) => {
  axiosConfig("/auth/token")
    .then((res) => {
      callback(res.data);
    })
    .catch((error) => {
      callback({ success: false });
    });
};

const updatePassword = (oldPassword, newPassword, callback) => {
  axiosConfig("/auth/update-password", {
    data: {
      oldPassword,
      newPassword,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const signout = (callback) => {
  axiosConfig("/auth/signout")
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

export { signin, token, updatePassword, signout };
