import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from "./routes";

import { token } from "./requests/auth";

import AuthContext from "./context/AuthContext";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      isConnected: false,
      userId: null,
      userInitial: null,
      userEmail: null,
    };
  }

  componentDidMount() {
    this.checkAuth();
  }

  checkAuth = () => {
    if (!document.location.href.includes("signout")) {
      token((result) => {
        const oldState = this.state;
        let newState = {};
        if (result.success) {
          newState = {
            isReady: true,
            isConnected: true,
            userId: result.id,
            userInitial: result.email.substring(0, 1).toUpperCase(),
            userEmail: result.email,
          };
        } else {
          newState = {
            isReady: true,
            isConnected: false,
            userId: null,
            userInitial: null,
            userEmail: null,
          };
        }
        if (JSON.stringify(oldState) !== JSON.stringify(newState)) {
          this.setState(newState);
        }
      });
    } else {
      this.setState({
        isReady: true,
        isConnected: false,
        userId: null,
        userInitial: null,
        userEmail: null,
      });
    }
  };

  render() {
    let style = "";
    if (document.location.hostname === "pic2folder.sahadevia.fr") {
      style = "<style>*{--primary-color: #ffb842 !important;}</style>";
    } else if (document.location.hostname === "nedey.w-t.fr") {
      style = "<style>*{--primary-color: #13a6ce !important;}</style>";
    } else if (document.location.hostname === "bci.w-t.fr") {
      style = "<style>*{--primary-color: #1875b4 !important;}</style>";
    } else if (document.location.hostname === "desk.w-t.fr") {
      style = "<style>*{--primary-color: #32ba94 !important;}</style>";
    }

    return (
      <AuthContext.Provider
        value={{
          userId: this.state.userId,
          userInitial: this.state.userInitial,
          userEmail: this.state.userEmail,
        }}
      >
        {<article dangerouslySetInnerHTML={{ __html: style }} />}
        <Router basename={process.env.REACT_APP_BASENAME || ""}>
          <Switch>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={(props) => {
                    return (
                      <route.layout
                        {...props}
                        isReady={this.state.isReady}
                        isConnected={this.state.isConnected}
                      >
                        <route.component {...props} />
                      </route.layout>
                    );
                  }}
                />
              );
            })}
          </Switch>
        </Router>
      </AuthContext.Provider>
    );
  }
}
