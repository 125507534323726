import React from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Title, Button, Loader } from "ui-kit-sahadevia";

import { getPictures } from "../../requests/pictures";

import Pictures from "../../components/home/Pictures";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: true,
      isArchived: false,
      pictures: [],
    };
  }

  componentDidMount() {
    this.getPictures();
  }

  getPictures = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        getPictures(this.state.isArchived, (result) => {
          if (result.success) {
            this.setState({ pictures: result.data, isLoad: false });
          } else {
            this.setState({ pictures: [], isLoad: false });
          }
        });
      }
    );
  };

  render() {
    return (
      <>
        <div style={{ padding: "1rem" }}>
          <Title title="Mes photos">
            <Button
              icon={this.state.isArchived ? faEyeSlash : faEye}
              text={
                this.state.isArchived
                  ? "Masquer les photos archivées"
                  : "Afficher les photos archivées"
              }
              onClick={() =>
                this.setState({ isArchived: !this.state.isArchived }, () =>
                  this.getPictures()
                )
              }
            />
          </Title>
        </div>
        <Pictures pictures={this.state.pictures} />
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
