import React from "react";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Title, Button, Loader, Card } from "ui-kit-sahadevia";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import {
  getPicturesReference,
  downloadPictures,
  deleteWithIdPicture,
} from "../../requests/pictures";

import { Col, Row } from "react-flexbox-grid";

export default class Pictures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: true,
      pictures: [],
      current: 0,
    };
  }

  componentDidMount() {
    this.getPicturesReference();
  }

  getPicturesReference = () => {
    getPicturesReference(this.props.match.params.reference, (result) => {
      if (result.success) {
        this.setState({
          pictures: result.data,
          isLoad: false,
        });
      } else {
        this.setState({
          pictures: [],
          isLoad: false,
        });
      }
    });
  };

  downloadPictures = (reference) => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        downloadPictures(reference, (result) => {
          if (result.success) {
            this.setState(
              {
                isLoad: false,
              },
              () => {
                const a = window.document.createElement("a");
                a.href = result.file;
                a.download = result.name;
                window.document.body.appendChild(a);
                a.click();
                window.document.body.removeChild(a);
              }
            );
          } else {
            this.setState(
              {
                isLoad: false,
              },
              () => {
                alert("Une erreur est survenue !");
              }
            );
          }
        });
      }
    );
  };

  deleteWithIdPicture = (id, idx) => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        deleteWithIdPicture(id, (result) => {
          if (result.success) {
            let tmpPictures = this.state.pictures;
            tmpPictures.splice(idx, 1);

            this.setState(
              {
                isLoad: false,
                pictures: tmpPictures,
              },
              () => {
                alert("La photo a été supprimée");
              }
            );
          } else {
            this.setState(
              {
                isLoad: false,
              },
              () => {
                alert("Une erreur est survenue !");
              }
            );
          }
        });
      }
    );
  };

  updateCurrent = (idx) => {
    this.setState({
      current: idx + 1,
    });
  };

  render() {
    return (
      <>
        <div style={{ padding: "1rem" }}>
          <Title title={`Photos dossier ${this.props.match.params.reference}`}>
            <Button
              icon={faDownload}
              text={"Télécharger toutes les photos du dossier"}
              onClick={() =>
                this.downloadPictures(this.props.match.params.reference)
              }
            />
          </Title>
        </div>
        <div
          style={{ padding: "0 1rem", margin: "0 1rem", marginBottom: "1rem" }}
        >
          <Card title="Photos">
            <Row>
              {this.state.pictures.map((picture, idx) => {
                if (this.state.current >= idx) {
                  return (
                    <Col xs={12} md={4} lg={3} key={idx}>
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        <div
                          className="cursor-pointer"
                          style={{
                            position: "absolute",
                            display: "flex",
                            padding: "10px",
                            backgroundColor: "rgba(255, 255, 255, 0.4)",
                            right: 0,
                            top: 0,
                          }}
                          onClick={() => {
                            if (
                              window.confirm(
                                "Êtes-vous sûr de vouloir supprimer cette photo ?"
                              )
                            ) {
                              this.deleteWithIdPicture(picture.id, idx);
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              fontSize: "20px",
                              margin: "auto",
                              color: "#db2828",
                            }}
                            icon={faTrash}
                          />
                        </div>
                        <img
                          alt={`dossier ${idx}`}
                          style={{ maxHeight: "100%", maxWidth: "100%" }}
                          onLoad={() => this.updateCurrent(idx)}
                          onAbort={() => this.updateCurrent(idx)}
                          onError={() => this.updateCurrent(idx)}
                          src={`/api/data/get-picture/${picture.id}`}
                        />
                      </div>
                    </Col>
                  );
                } else {
                  return null;
                }
              })}
            </Row>
          </Card>
        </div>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
