import React from "react";

import { signin } from "../../requests/auth";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { Input, Button, Loader } from "ui-kit-sahadevia";

export default class Signin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      email: "",
      password: "",
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  signin = () => {
    if (this.state.email && this.state.password) {
      this.setState(
        {
          isLoad: true,
        },
        () => {
          signin(this.state.email, this.state.password, (result) => {
            if (result.success) {
              document.location.reload(true);
            } else {
              this.setState(
                {
                  isLoad: false,
                },
                () => alert("wrong login !")
              );
            }
          });
        }
      );
    } else {
      alert("wrong input !");
    }
  };

  render() {
    return (
      <>
        <div className="Signin-container">
          <h1>Se connecter</h1>
          <Input
            type="email"
            placeholder="Adresse E-mail"
            name="email"
            value={this.state.email}
            onChange={this.handleInputChange}
          />
          <Input
            type="password"
            placeholder="Mot de passe"
            name="password"
            value={this.state.password}
            onChange={this.handleInputChange}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                this.signin();
              }
            }}
          />
          <div style={{ display: "flex" }}>
            {this.state.isError ? (
              <p style={{ margin: "auto", marginLeft: 0, color: "#B71C1C" }}>
                Identifiant incorrect
              </p>
            ) : null}
            <Button
              style={{ marginLeft: "auto" }}
              text="Continuer"
              icon={faArrowRight}
              onClick={this.signin}
            />
          </div>
        </div>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
