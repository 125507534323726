import React from "react";
import { Title, Loader, Card, Input, Button } from "ui-kit-sahadevia";
import { Row, Col } from "react-flexbox-grid";

import { signin, updatePassword } from "../../requests/auth";

import AuthContext from "../../context/AuthContext";

export default class Profil extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleUpdatePasswordClick = () => {
    if (this.state.oldPassword && this.state.newPassword) {
      if (this.state.newPassword === this.state.confirmPassword) {
        this.setState({ isLoad: true }, () => {
          updatePassword(
            this.state.oldPassword,
            this.state.newPassword,
            (result) => {
              if (result.success) {
                signin(
                  this.context.userEmail,
                  this.state.newPassword,
                  (resultSignin) => {
                    if (resultSignin.success) {
                      this.setState({
                        isLoad: false,
                        oldPassword: "",
                        newPassword: "",
                        confirmPassword: "",
                      });
                    } else {
                      document.location.reload(true);
                    }
                  }
                );
                alert("Mot de passe modifié");
              } else {
                this.setState({ isLoad: false }, () => {
                  alert("Une erreur est survenue");
                });
              }
            }
          );
        });
      } else {
        alert("Les deux mots de passes ne correspondent pas");
      }
    } else {
      alert("Veuillez remplir tous les champs obligatoires");
    }
  };

  render() {
    return (
      <>
        <div style={{ padding: "1rem" }}>
          <Title title="Mon profil" />
        </div>
        <div
          style={{ padding: "0 1rem", margin: "0 1rem", marginBottom: "1rem" }}
        >
          <Card title="Modification du mot de passe">
            <Row>
              <Col xs={12} md={4}>
                <Input
                  title="Ancien mot de passe"
                  type="password"
                  placeholder="*******"
                  name="oldPassword"
                  value={this.state.oldPassword}
                  onChange={this.handleInputChange}
                />
              </Col>
              <Col xs={12} md={4}>
                <Input
                  title="Nouveau mot de passe"
                  type="password"
                  placeholder="*******"
                  name="newPassword"
                  value={this.state.newPassword}
                  onChange={this.handleInputChange}
                />
              </Col>
              <Col xs={12} md={4}>
                <Input
                  title="Confirmer le mot de passe"
                  type="password"
                  placeholder="*******"
                  name="confirmPassword"
                  value={this.state.confirmPassword}
                  onChange={this.handleInputChange}
                />
              </Col>
            </Row>
            <Button
              disabled={true}
              text="Modifier"
              onClick={this.handleUpdatePasswordClick}
            />
          </Card>
        </div>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
