import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import Auth from "./layouts/Auth";
import Dashboard from "./layouts/Dashboard";

// Route Views
import Signin from "./views/auth/Signin";
import Profil from "./views/dashboard/Profil";
import Home from "./views/dashboard/Home";
import Pictures from "./views/dashboard/Pictures";

const routes = [
  {
    path: "/",
    exact: true,
    layout: Auth,
    component: () => <Redirect to="/signin" />,
  },
  {
    path: "/signin",
    exact: true,
    layout: Auth,
    component: Signin,
  },
  {
    path: "/home",
    exact: true,
    layout: Dashboard,
    component: Home,
  },
  {
    path: "/profil",
    exact: true,
    layout: Dashboard,
    component: Profil,
  },
  {
    path: "/pictures/:reference",
    exact: true,
    layout: Dashboard,
    component: Pictures,
  },
];

export default routes;
