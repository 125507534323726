import axiosConfig from "../utils/axiosConfig";

const getPictures = (isArchived, callback) => {
  axiosConfig("/data/get", { data: { isArchived } })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getPicturesReference = (reference, callback) => {
  axiosConfig("/data/pictures", {
    data: {
      reference,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const downloadPictures = (reference, callback) => {
  axiosConfig("/data/download", {
    data: {
      reference,
    },
    timeout: 360000,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const deleteWithIdPicture = (id, callback) => {
  axiosConfig("/data/delete-width-id", {
    data: {
      id,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

export { getPictures, getPicturesReference, downloadPictures, deleteWithIdPicture };
