import React from "react";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWrench } from "@fortawesome/free-solid-svg-icons";
import { Header, HeaderProfil } from "ui-kit-sahadevia";

import { signout } from "../requests/auth";

import logoPic2Folder from "../images/logo_pic2folder-nobg.png";
import logoNedey from "../images/logo_nedey.png";
import logoBCI from "../images/logo_bci.png";
import logoDesk from "../images/logo_desk.png";

import AuthContext from "../context/AuthContext";

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInformationMenu: false,
      isNotificationMenu: false,
      isProfilMenu: false,
    };
  }

  static contextType = AuthContext;

  render() {
    if (!this.props.isReady) {
      return null;
    } else if (!this.props.isConnected) {
      return <Redirect to="/signin" />;
    }

    let logo = "";
    if (document.location.hostname === "pic2folder.sahadevia.fr") {
      logo = logoPic2Folder;
    } else if (document.location.hostname === "nedey.w-t.fr") {
      logo = logoNedey;
    } else if (document.location.hostname === "bci.w-t.fr") {
      logo = logoBCI;
    } else if (document.location.hostname === "desk.w-t.fr") {
      logo = logoDesk;
    }

    return (
      <div>
        <Header
          logo={logo}
          onLogoClick={() => {
            this.props.history.push("/home");
          }}
          left={<></>}
          right={
            <>
              <HeaderProfil
                onClick={() =>
                  this.setState({
                    isProfilMenu: !this.state.isProfilMenu,
                    isInformationMenu: false,
                    isNotificationMenu: false,
                  })
                }
                text={this.context.userInitial}
                email={this.context.userEmail}
                grade={`version ${process.env.REACT_APP_VERSION}`}
                active={this.state.isProfilMenu}
                onProfilClick={() => (document.location.href = "/profil")}
                onSignoutClick={() =>
                  signout(() => {
                    document.location.reload();
                  })
                }
              />
            </>
          }
        />
        {this.props.children ? (
          this.props.children
        ) : (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "auto",
                color: "#959595",
              }}
            >
              <FontAwesomeIcon
                style={{ fontSize: "140px", margin: "auto" }}
                icon={faWrench}
              />
              <span
                style={{
                  paddingTop: "2rem",
                  fontSize: "22px",
                  margin: "auto",
                }}
              >
                Page en cours de construction...
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
