import React from "react";
import { Card, Input } from "ui-kit-sahadevia";
import { Row, Col } from "react-flexbox-grid";

export default class Pictures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    let total = 0;

    const keys = Object.keys(this.props.pictures).filter(
      (key) =>
        !this.state.search ||
        this.props.pictures[key].reference.includes(this.state.search)
    );

    this.props.pictures.forEach((picture) => {
      if (!this.state.search || picture.reference.includes(this.state.search)) {
        total += picture.pictures;
      }
    });

    return (
      <>
        <div
          style={{ padding: "0 1rem", margin: "0 1rem", marginBottom: "1rem" }}
        >
          <Card title="Filtres">
            <Row>
              <Col xs={12} md={4}>
                <Input
                  title="Référence"
                  placeholder="Référence"
                  name="search"
                  value={this.state.search}
                  onChange={this.handleInputChange}
                />
              </Col>
            </Row>
          </Card>
        </div>
        <div
          style={{ padding: "0 1rem", margin: "0 1rem", marginBottom: "1rem" }}
        >
          <Card title={`Photos (${total})`}>
            <Row>
              {keys.map((key) => (
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  key={key}
                  style={{ marginBottom: "1rem" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: "160px",
                      backgroundColor: "#2e3339",
                      color: "#ffffff",
                      borderRadius: "4px",
                      overflow: "hidden",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (this.props.pictures[key].archived === 0) {
                        document.location.href = `pictures/${this.props.pictures[key].reference}`;
                      } else {
                        alert("Le dossier est archivé");
                      }
                    }}
                  >
                    <p
                      style={{
                        margin: "auto",
                        marginBottom: 0,
                      }}
                    >
                      ({this.props.pictures[key].pictures} photos)
                    </p>
                    <p
                      style={{
                        margin: "auto",
                        marginBottom: 0,
                        marginTop: "5px",
                      }}
                    >
                      {this.props.pictures[key].date}
                    </p>

                    {this.props.pictures[key].archived === 1 ? (
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "auto",
                          marginBottom: 0,
                          marginTop: "5px",
                          backgroundColor: "#db2828",
                          color: "#ffffff",
                          padding: "4px",
                          borderRadius: "4px",
                        }}
                      >
                        Archivées !
                      </p>
                    ) : (
                      <>
                        {this.props.pictures[key].status === false ? (
                          <p
                            style={{
                              fontSize: "14px",
                              margin: "auto",
                              marginBottom: 0,
                              marginTop: "5px",
                              backgroundColor: "#f2711c",
                              color: "#ffffff",
                              padding: "4px",
                              borderRadius: "4px",
                            }}
                          >
                            Erreur de transfert !
                          </p>
                        ) : null}
                      </>
                    )}
                    <div
                      style={{
                        marginTop: "auto",
                        backgroundColor: "var(--primary-color)",
                        textAlign: "center",
                      }}
                    >
                      <p>{this.props.pictures[key].reference}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Card>
        </div>
      </>
    );
  }
}
