import React from "react";
import { Redirect } from "react-router-dom";

import styles from "../style/Auth.module.css";

import logoPic2Folder from "../images/logo_pic2folder-nobg.png";
import logoNedey from "../images/logo_nedey.png";
import logoBCI from "../images/logo_bci.png";
import logoDesk from "../images/logo_desk.png";

import splash from "../images/splash.svg";

export default class Auth extends React.Component {
  render() {
    if (!this.props.isReady) {
      return null;
    } else if (this.props.isConnected) {
      return <Redirect to="/home" />;
    }

    let logo = "";
    if (document.location.hostname === "pic2folder.sahadevia.fr") {
      logo = logoPic2Folder;
    } else if (document.location.hostname === "nedey.w-t.fr") {
      logo = logoNedey;
    } else if (document.location.hostname === "bci.w-t.fr") {
      logo = logoBCI;
    } else if (document.location.hostname === "desk.w-t.fr") {
      logo = logoDesk;
    }

    return (
      <div className={styles.auth}>
        <div className={styles.auth_left}>
          <img
            alt="background"
            src={splash}
            className={styles.auth_left_background}
          />
          <div className={styles.auth_left_top}>
            <img src={logo} alt="logo" />
          </div>
          <div className={styles.auth_left_middle}>
            <h2>Bienvenue dans votre espace personnalisé</h2>
            <p>Connectez-vous pour continuer</p>
          </div>
          <div className={styles.auth_left_bottom}>
            <a href={document.location.origin}>
              {document.location.origin}
            </a>
          </div>
        </div>
        <div className={styles.auth_right}>
          <div className={styles.auth_right_container}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
